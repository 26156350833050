export default {
  "current_deal": "Nåværende tilbud",
  "lbg.section_properties_theme.pet_friendly": "Vinnere i kategorien «Kjæledyrvennlig» i {0}",
  "address": "Adresse",
  "hub.homepage.h2": "Så mye å oppleve, så lite tid ...",
  "search_hotels_near_this_landmark": "Søk etter hoteller<span class=\"extra-label\"> nær denne severdigheten</span>",
  "hub.category.h1.things_to_do": "Aktiviteter i {0}",
  "lbg.h2.vip_access": "VIP Access-overnattingssteder er utvalgt for sine høyt rangerte opphold og førsteklasses opplevelser.",
  "lbg.theme.cleanliness": "Renhold",
  "lbg.theme.lgbtq": "LHBTQ",
  "load_more": "Last inn mer",
  "link_long_form_to_listiicle_multiple": "{0} – et av høydepunktene i «{1}» og «{2}». Les alt om {3} her.",
  "lbg.section_properties_theme.boutique": "Vinnere i kategorien «Boutique» i {0}",
  "lbg.h2.luxury": "Luksus på sitt absolutt beste. Det er derfor gjestene stortrives på disse overnattingsstedene.",
  "hub.category.h1.information": "Informasjon om {0}",
  "families": "Familier",
  "trending_now": "Populært nå",
  "lbg.from": "Fra",
  "hub.category.h1.road_trips": "De beste bilferiene i {0}",
  "lbg.h2.cleanliness": "Disse overnattingsstedene har høye gjestevurderinger generelt og den høyeste gjestevurderingen for renhold.",
  "single": "Enslige",
  "couples": "Par",
  "trust_module.price_guarantee.find_out_more": "finn ut mer",
  "top_destinations_in": "Mest populære reisemål i {0}",
  "hub.category.h1.couples": "Beste parreiser i {0}",
  "hub.category.h2.tours": "De beste omvisningene i {0}",
  "hub.category.h1.photos": "Fotovennlige reiser i {0}",
  "category.photos": "Bilder",
  "hub.category.h1.budget": "Lavprisreiser i {0}",
  "hub.category.h2.ski": "De beste skistedene i {0}",
  "lbg.section_theme_world_winners.most_wanted": "Vinnere i kategorien «Mest etterspurt»",
  "lbg.theme.home_and_apartments": "Hjem og leiligheter",
  "hub.category.h1.luxury": "Luksusreiser i {0}",
  "hub.category.h2.adventure": "Mest populære aktive reiser i {0}",
  "hotels_in": "Hoteller i {0}",
  "lbg.section_search_winners": "Søk etter prisvinnere",
  "see_more_hotels_in": "Se flere hoteller i:{0}",
  "hub.category.h1.beach": "Strender i {0}",
  "open": "Åpent:",
  "enter_your_destination": "Angi reisemål",
  "best_of": "Høydepunkter i {0}",
  "top10_hotels_missing_properties": "Noen av overnattingsstedene vises kanskje ikke akkurat nå. Vi beklager ulempene dette medfører.",
  "adventure": "Spenning",
  "lbg.h1.destination_theme.sustainable": "Vinnere i kategorien «Bærekraftig» i {0}",
  "luxury": "Luksus",
  "expand_map_view": "Større kart",
  "book_now": "Bestill nå",
  "lbg.h1.destination_theme.ski": "Vinnere i kategorien «Ski» i {0}",
  "hub.category.h1.food": "Mat og drikke i {0}",
  "category.blogs": "Blogg",
  "budget": "Lave priser",
  "popular_neighbourhoods_in": "Populære bydeler i {0}",
  "hub.category.h1.unusual": "Skjulte perler og uvanlige steder i {0}",
  "see_all_accommodation_in": "Se alle overnattingssteder i {0}",
  "hub.category.h1.adventure": "Aktive reiser i {0}",
  "category_highlight.see_all.shopping": "Mer om shopping",
  "lbg.section_most_popular_destination": "Mest populære reisemål",
  "lbg.section_properties_theme.luxury": "Vinnere i kategorien «Luksus» i {0}",
  "unusual": "Unikt",
  "category.shopping": "Shopping",
  "make_the_most_of_your_trip": "Få mest mulig ut av reisen din",
  "x_review": "{0,choice,0#|1#{0} anmeldelse|2#{0} anmeldelser|2<{0} anmeldelser|4<{0} anmeldelser|10#{0} anmeldelser|10<{0} anmeldelser}",
  "hub.category.h1.single": "Alenereiser i {0}",
  "hub.category.h2.custom": "Populært akkurat nå",
  "lbg.section_theme_world_winners.lgbt": "Vinnere i kategorien «LHBTQ+»",
  "lbg.section_award_winners_theme_other": "Andre kategorier i {0}",
  "lbg.supplier_collected_charges": "+ {0} som betales på overnattingsstedet",
  "in_destination": " i {0}",
  "lbg.section_properties_theme.vip_access": "Vinnere i kategorien «VIP Access» i {0}",
  "link_long_form_to_listiicle_single": "{0} – et av høydepunktene i «{1}». Les alt om {2} her.",
  "lbg.section_theme_world_winners.boutique": "Vinnere i kategorien «Boutique»",
  "lbg.section_properties_theme.spa_hotel": "Vinnere i kategorien «Spa» i {0}",
  "lbg.section_theme_world_winners.all_inclusive": "Vinnere i kategorien «Alt inkludert»",
  "hub.category.h2": "Reiseguide til {0}",
  "lbg.properties.price_tooltip": "Dette er den laveste prisen per natt på siden vår i løpet av de neste 14 dagene.",
  "hub.category.h2.road_trips": "De beste bilferiene i {0}",
  "hub.category.h1.ski": "Skisteder i {0}",
  "food": "Mat",
  "lbg.theme.pet_friendly": "Dyrevennlig",
  "lbg.theme.business": "Jobb",
  "hub.category.h1.nature": "De beste reisene for naturopplevelser i {0}",
  "map": "Kart",
  "category_highlight.see_all.information": "Mer informasjon",
  "hotel_prices_in": "Hotellpriser i {0}",
  "hub.category.h1.nightlife": "Uteliv i {0}",
  "trust_module.free_cancellation.most_hotels": "på de fleste hoteller*",
  "also_popular_in": "Også populært i {0}",
  "lbg.h1.destination_theme.business": "Vinnere i kategorien «Forretningsreise» i {0}",
  "lbg.section_theme_world_winners.pet_friendly": "Vinnere i kategorien «Kjæledyrvennlig»",
  "hub.category.h1.maps": "Kart over {0}",
  "lbg.section_properties_theme.most_wanted": "Vinnere i kategorien «Mest etterspurt» i {0}",
  "category.nightlife": "Natteliv",
  "search_hotels_in_this_area": "Søk etter hoteller<span class=\"extra-label\"> i dette området</span>",
  "votes_score_and_count": "<span>{0}</span> ({1,choice,0#|1#1 stemme|2#2 stemmer|2<{1} stemmer|4<{1} stemmer|10#10 stemmer|10<{1} stemmer})",
  "hub.category.h2.business": "Mest populære forretningsreiser i {0}",
  "category.things_to_do": "Ting å gjøre",
  "destination_travel_guide_by_category": "{0} Reiseguider etter kategori",
  "hub.category.h1.families": "Familiereiser i {0}",
  "lbg.section_theme_world_winners.ski": "Vinnere i kategorien «Ski»",
  "you_may_also_like": "Kanskje vil du også like",
  "lbg.theme.sustainable": "Bærekraftig",
  "hub.category.h2.unusual": "Mest populære skjulte perler og uvanlige steder i {0}",
  "hub.category.h2.football": "Støtt fotballaget ditt og få mest mulig ut av reisen",
  "top_hotel": "Utmerket hotell",
  "lbg.h1.destination_theme.luxury": "Vinnere i kategorien «Luksus» i {0}",
  "top10_hotels_empty_alert": "Vi har noen tekniske problemer for øyeblikket. <span>Vi beklager ulempene dette medfører.</span>",
  "special_deal": "Spesialtilbud",
  "lbg.h2.sustainable": "Bærekraft er viktig! Sjekk ut disse overnattingsstedene for et fantastisk opphold.",
  "where_to_stay_in": "Hvor du bør bo i {0}",
  "start_planning_your_trip": "Planlegg reisen din",
  "good_for": "Perfekt for:",
  "category.hotels": "Hoteller",
  "x_accommodation_guide": "Guide til overnattingssteder i {0}",
  "lbg.theme.luxury": "Luksus",
  "hub.category.h2.sports": "Mest populære sporter og aktiviteter i {0}",
  "lbg.section_theme_world_winners.lgbtq": "Vinnere i kategorien «LHBTQ»",
  "see_more_neighbourhoods": "Se flere områder",
  "category_highlight.title.nightlife": "Hvor du kan gå ut og hva du kan gjøre på kveldstid i {0}",
  "lbg.h1.destination_theme.lgbt": "Vinnere i kategorien «LHBTQ+» i {0}",
  "hub.category.h1.blogs": "Reiseblogg om {0}",
  "lbg.h2.business": "Disse overnattingsstedene er høyt rangert av forretningsreisende.",
  "hotels": "Hoteller",
  "lbg.h2.pet_friendly": "Disse dyrevennlige overnattingsstedene anbefales på det varmeste av gjester.",
  "popular_landmarks_nearby": "Populære severdigheter i nærheten",
  "popular_extractions_in": "Populære attraksjoner i {0}",
  "lbg.h2.all_inclusive": "Disse topprangerte overnattingsstedene lar deg slappe helt av.",
  "travel_guide_by_category_header": "Det beste av: {0} i {1}",
  "lbg.travel_between": "Reis mellom {0} og {1}",
  "keep_exploring": "Fortsett å utforske",
  "what_you_need_to_know_before_you_go": "Kjekt å vite før du reiser",
  "read_it": "Les videre",
  "view_on_map": "Se på kart",
  "lbg.h1.destination_theme.lgbtq": "Vinnere i kategorien «LHBTQ» i {0}",
  "lbg.h2.beach": "Gjestene elsker en strandferie, spesielt på disse overnattingsstedene.",
  "lbg.h1.destination_theme.vip_access": "Vinnere i kategorien «VIP Access» i {0}",
  "see_all_accommodation": "Se alle overnattingssteder",
  "shoppers": "Shopping",
  "whats_around": "i nærheten av {0}",
  "group": "Grupper",
  "hub.category.h2.couples": "Mest populære parreiser i {0}",
  "hub.category.h1.shopping": "Shopping i {0}",
  "travel_community": "{0} <span>&#124;</span> <span>Reisecommunity</span>",
  "hub.category.h2.group": "Mest populære gruppereiser i {0}",
  "listicle_legal_msg": "Denne artikkelen inkluderer Go Guides-redaksjonens meninger og synspunkter. Hotels.com kompenserer skribentene for tekster vi bruker på dette nettstedet. Dette kan inkludere kompensasjon for reiser og andre kostnader.",
  "lbg.h1.destination_theme.cleanliness": "Vinnere i kategorien «Renhold» i {0}",
  "menu.more": "Mer",
  "lbg.section_properties_theme.lgbtq": "Vinnere i kategorien «LHBTQ» i {0}",
  "lbg.section_award_winners_destination": "Prisvinnere sortert etter land",
  "lbg.terms_conditions": "Vilkår og betingelser",
  "hub.category.h2.nightlife": "De beste utestedene i {0}",
  "hub.neighbourhood.h2": "Ting å finne på i {0}",
  "lbg.section_award_winners_theme_destination": "Prisvinnere sortert etter kategori i {0}",
  "lbg.section_award_world_winners": "Prisvinnere over hele verden",
  "things_to_see_and_do_in_other_cities": "Ting å se og gjøre i andre byer",
  "button.backToTop": "Tilbake til toppen",
  "lbg.section_theme_world_winners.family": "Vinnere i kategorien «Familie»",
  "hotels_near": "Hoteller nær {0}",
  "category.activity__sports_": "Aktiviteter",
  "trust_module.get_reward_night": "Få en bonusovernatting",
  "hub.category.h1.business": "Forretningsreiser i {0}",
  "category.food": "Mat",
  "other_popular_destinations_in": "Andre populære reisemål i {0}",
  "browse_by_category": "{0} sortert etter kategorier",
  "see_more_attactions": "Vis flere severdigheter",
  "lbg.theme.lgbt": "LHBTQ+",
  "more_information_about": "Mer informasjon om {0}",
  "lbg.h1.destination_theme.most_wanted": "Vinnere i kategorien «Mest etterspurt» i {0}",
  "see_all_properties_in": "{0} – se overnattingssteder",
  "category.tours": "Organiserte turer",
  "hub.category.h1.sports": "Sport og aktiviteter i {0}",
  "where_will_you_go_next": "Hvor går din neste reise?",
  "cities": "Byer",
  "lbg.theme.spa_hotel": "Spa",
  "see_guide": "Vis guiden",
  "lbg.section_properties_theme.ski": "Vinnere i kategorien «Ski» i {0}",
  "related_stories": "Relaterte saker",
  "hub.destination.h2": "Kjekt å vite før du reiser",
  "show_less": "Vis færre",
  "hub.category.h1.football": "Den ultimate {0}-guiden for ekte fotballfans",
  "hub.category.h2.food": "Den beste maten i {0}",
  "fallback_language_alert": "Beklager, denne siden er ikke oversatt til språket ditt ennå … ",
  "category_highlight.title.information": "Nyttig informasjon",
  "neighbourhood_guides_for_other_cities": "Bydelguider for andre byer",
  "lbg.h2.hubpage": "Disse fantastiske overnattingsstedene har en høy gjestevurdering og mange anmeldelser.",
  "travel_advisory.title": "Reisevarsel",
  "lbg.section_properties_theme.all_inclusive": "Vinnere i kategorien «Alt inkludert» i {0}",
  "lbg.h2.boutique": "Hvis du liker boutique-overnatting, er disse stedene anbefalt på det varmeste av gjester.",
  "lbg.section_properties_theme.family": "Vinnere i kategorien «Familie» i {0}",
  "hub.category.h2.families": "De beste familiereisene i {0}",
  "from": "fra",
  "lbg.theme.vip_access": "VIP Access",
  "lbg.section_properties_theme.lgbt": "Vinnere i kategorien «LHBTQ+» i {0}",
  "x_star": "{0,choice,0#|1#{0}-stjernes|1.5#{0}-stjernes|2#{0}-stjerners|2.5#{0}-stjerners|3#{0}-stjerners|3.5#{0}-stjerners|4#{0}-stjerners|4.5#{0}-stjerners|5#{0}-stjerners}",
  "trust_module.free_cancellation": "Gratis avbestilling",
  "lbg.section_properties_theme.home_and_apartments": "Vinnere i kategorien «Hjem og leiligheter» i {0}",
  "contributing_writer": "{0} <span>&#124;</span> <span>Bidragsyter</span>",
  "things_to_see_and_do_in": "Ting å se og gjøre <span class=\"next-line\">i {0}</span>",
  "lbg.check_price": "Sjekk pris",
  "hub.category.h2.blogs": "av erfarne reisende ",
  "lbg.h2.home_and_apartments": "Er du på utkikk etter et hjemmekoselig sted å bo? Ta en titt på disse høyt rangerte overnattingsstedene.",
  "hub.category.h2.information": "Nyttig info om {0}",
  "search_hotels_in": "Søk etter hoteller<span class=\"extra-label\"> i {0}</span>",
  "hub.category.h2.nature": "De beste reisene til naturen i {0}",
  "lbg.h1.destination_theme.home_and_apartments": "Vinnere i kategorien «Hjem og leiligheter» i {0}",
  "photo_by": "Foto: {0}",
  "more_things_to_do": "Flere ting å gjøre",
  "lbg.section_properties_theme.beach": "Vinnere i kategorien «Strand» i {0}",
  "read_less": "Les mindre",
  "lbg.h2.spa_hotel": "Slapp av og lad batteriene på disse topprangerte overnattingsstedene kjent for sine spa.",
  "second_fold_related_stories.see_also": "Se også",
  "hub.category.h2.history": "Mest populære historiske steder i {0}",
  "lbg.section_properties_theme.cleanliness": "Vinnere i kategorien «Renhold» i {0}",
  "lbg.h1.destination_theme.boutique": "Vinnere i kategorien «Boutique» i {0}",
  "nightlife_b595ae4": "Natteliv",
  "lbg.h1.destination_theme.beach": "Vinnere i kategorien «Strand» i {0}",
  "trust_module.free_cancellation.disclaimer": "Noen hoteller krever at du avbestiller minst 24−48 timer før innsjekking. Mer informasjon på nettstedet.",
  "lbg.h2.family": "Disse overnattingsstedene er høyt rangert av gjester og perfekt for familier.",
  "lbg.h2.lgbtq": "Disse LHBTQ-vennlige overnattingsstedene er høyt rangert av gjester.",
  "hub.category.h1.group": "Gruppereiser i {0}",
  "popular_stories": "Populære historier",
  "toc": "Tekst",
  "hub.category.h2.shopping": "De beste shoppingtipsene for {0}",
  "lbg.section_theme_world_winners.beach": "Vinnere i kategorien «Strand»",
  "page_score": "Denne sidens poengsum",
  "lbg.section_theme_world_winners.sustainable": "Vinnere i kategorien «Bærekraftig»",
  "lbg.theme.boutique": "Boutique",
  "trust_module.price_guarantee": "Vår prismatch",
  "lbg.h1.destination_theme.all_inclusive": "Vinnere i kategorien «Alt inkludert» i {0}",
  "top_destinations": "Mest populære reisemål",
  "hotel_prices_near": "Hotellpriser nær {0}",
  "lbg.score.cleanliness": "Renhold:",
  "x_stars": "{0,choice,0#|1#{0} stjerne|1<{0} stjerner|4<{0} stjerner|10<{0} stjerner}",
  "lbg.theme.all_inclusive": "Alt inkludert",
  "hub.category.h1.tours": "Omvisninger og dagsturer i {0}",
  "lbg.section_award_winners_theme": "Prisvinnere sortert etter kategori",
  "guest_writer": "{0} <span>&#124;</span> <span>Gjesteskribent</span>",
  "start_planning_your_x_trip": "Planlegg reisen din til {0}",
  "lbg.section_theme_world_winners.business": "Vinnere i kategorien «Forretningsreise»",
  "trending_now_in": "Populært i {0} nå",
  "history": "Historie",
  "search_hotels": "Søk på hoteller",
  "lbg.terms_conditions_details": "Kriteriene for at et overnattingssted skulle vinne i {0}, var at det måtte ha høye gjestevurderinger i {1} og minst 50 gjesteanmeldelser.",
  "see_nearby_properties": "Se overnattingssteder i nærheten",
  "hub.category.h1.history": "Historiske steder i {0}",
  "location": "Beliggenhet:",
  "lbg.section_theme_world_winners.luxury": "Vinnere i kategorien «Luksus»",
  "category_highlight.see_all.nightlife": "Mer om uteliv",
  "also_popular": "Også populært",
  "hub.category.h2.things_to_do": "De beste severdighetene i {0}",
  "lbg.theme.family": "Familie",
  "lbg.h1.destination_theme.pet_friendly": "Vinnere i kategorien «Kjæledyrvennlig» i {0}",
  "category_highlight.title.shopping": "Hvor du kan dra på shopping og hva du bør kjøpe i {0}",
  "price": "Pris:",
  "hub.category.h2.beach": "De beste strendene i {0}",
  "neighbourhoods_in": "områder i {0}",
  "business": "Forretninger",
  "photo": "Fotografering",
  "blog.see_all_stories": "Se alle sakene",
  "category_highlight.see_all.food": "Mer om mat og restauranter",
  "see_all_properties": "Se alle overnattingssteder",
  "lbg.theme.beach": "Strand",
  "what_to_see_and_do_in": "Hva du kan se og gjøre i {0}",
  "hub.category.h2.single": "Mest populære alenereiser i {0}",
  "attractions_in": "Severdigheter i {0}",
  "read_more": "Les mer",
  "show_more": "Vis flere",
  "hotels_near_this_landmark": "Hoteller nær denne severdigheten",
  "check_dates": "Sjekk datoer",
  "featured_stories_and_fun_stuff": "Utvalgte artikler og morsomme saker",
  "phone": "Telefonnummer:",
  "lbg.section_search_header": "Søk etter prisvinnere",
  "category_highlight.title.food": "Hva og hvor du bør spise i {0}",
  "see_all_things_to_do": "Se alle ting å gjøre",
  "lbg.section_properties_theme.sustainable": "Vinnere i kategorien «Bærekraftig» i {0}",
  "lbg.h1.hubpage": "Vinnere av «Elsket av våre gjester»-prisen {0}",
  "lbg.section_theme_world_winners.spa_hotel": "Vinnere i kategorien «Spa»",
  "category.maps": "Kart",
  "lbg.h1.destination_theme.family": "Vinnere i kategorien «Familie» i {0}",
  "lbg.section_theme_world_winners.cleanliness": "Vinnere i kategorien «Renhold»",
  "lbg.section_theme_world_winners.vip_access": "Vinnere i kategorien «VIP Access»",
  "trust_module.get_reward_night.night_you_stay": "for hver 10. natt du gjennomfører",
  "modified": "retusjert",
  "lbg.section_properties": "Prisvinnere i {0}",
  "trust_module.header": "Hvorfor bestille med Hotels.com?",
  "hub.category.h2.luxury": "Mest populære luksusreiser i {0}",
  "lbg.theme.ski": "Ski",
  "lbg.h1.destination_theme.spa_hotel": "Vinnere i kategorien «Spa» i {0}",
  "lbg.h2.most_wanted": "Disse overnattingsstedene tilbyr et perfekt opphold og anbefales på det varmeste av gjester.",
  "top10_hotels_incomplete_alert": "Vi opplever for tiden stor pågang, noe som betyr at enkelte overnattingssteder ikke kan vises akkurat nå. <span>Vi beklager ulempene dette medfører.</span>",
  "compulsive_traveller": "{0} <span>&#124;</span> <span>Reiseentusiast</span>",
  "lbg.section_theme_world_winners.home_and_apartments": "Vinnere i kategorien «Hjem og leiligheter»",
  "loved_by_guests_collection_in": "«Elsket av våre gjester»-overnattingssteder i {0}",
  "title.suffix": "{0} – Go Guides",
  "hub.category.h2.maps": "Nyttige kart over {0}",
  "opening_hours": "Åpningstider",
  "hub.category.h2.photos": "Mest populære fotovennlige reiser i {0}",
  "lbg.theme.most_wanted": "Mest etterspurt",
  "blog.stories_container": "Saker fra bidragsytere",
  "hub.category.h2.budget": "Mest populære lavprisreiser i {0}",
  "lbg.section_properties_theme.business": "Vinnere i kategorien «Forretningsreise» i {0}",
  "not_sure_where_to_stay": "Ikke sikker på hvor du skal bo?",
  "category.information": "Informasjon"
};