var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { useEffect, useState } from 'react';
import { useWindowSize } from '../common/helper';
import { checkValue } from '../functions';
import * as React from 'react';
import { UitkImage } from 'uitk-react-images';
import { Viewport, ViewLarge, ViewMedium, ViewSmall, ViewExtraLarge } from "@shared-ui/viewport-context";
export var getResponsiveImageUrl = function (props) {
  var _a;
  var imageUrl = props.imageUrl,
    params = props.params;
  var _b = __read(useState('desktop'), 2),
    windowSize = _b[0],
    setWindowSize = _b[1];
  var imgParam = '?anchor=center&mode=crop';
  var sizeParams = params.size ? (_a = params.size) === null || _a === void 0 ? void 0 : _a[windowSize] : '';
  var qualityParam = params.quality ? params.quality : '';
  var _c = __read(useState("".concat(imageUrl).concat(imgParam).concat(sizeParams).concat(qualityParam)), 2),
    sourceImageUrl = _c[0],
    setSourceImageUrl = _c[1];
  var _d = __read(useWindowSize(), 1),
    width = _d[0];
  var screenSize = {
    s: 599,
    m: 749,
    l: 959
  };
  useEffect(function () {
    var _a;
    var deviceView = 'mobile';
    if (width > screenSize.l) {
      deviceView = 'desktop';
    } else if (width > screenSize.m) {
      deviceView = 'tablet';
    }
    if (deviceView !== windowSize) {
      setWindowSize(deviceView);
    }
    var sizeParams = params.size ? (_a = params.size) === null || _a === void 0 ? void 0 : _a[windowSize] : '';
    var newImageUrl = checkValue(imageUrl) ? "".concat(imageUrl).concat(imgParam).concat(sizeParams).concat(qualityParam) : '';
    if (newImageUrl !== sourceImageUrl) {
      setSourceImageUrl(newImageUrl);
    }
  }, [width, imageUrl, params, qualityParam, imgParam, windowSize, sourceImageUrl]);
  return sourceImageUrl;
};
export var RenderImage = function (_a) {
  var imgProps = _a.imgProps;
  var image = imgProps.image,
    params = imgProps.params,
    alt = imgProps.alt;
  var imageUrl = image;
  var imgParam = '?anchor=center&mode=crop';
  var imgQuality = '&quality=50';
  var _b = params.size,
    small = _b.small,
    medium = _b.medium,
    large = _b.large,
    exLarge = _b.exLarge;
  var imageSourceSmall = checkValue(small) ? "".concat(imageUrl).concat(imgParam).concat(small).concat(imgQuality) : null;
  var imageSourceMedium = checkValue(medium) ? "".concat(imageUrl).concat(imgParam).concat(medium).concat(imgQuality) : null;
  var imageSourceLarge = checkValue(large) ? "".concat(imageUrl).concat(imgParam).concat(large).concat(imgQuality) : null;
  var imageSourceExLarge = checkValue(exLarge) ? "".concat(imageUrl).concat(imgParam).concat(exLarge).concat(imgQuality) : null;
  return React.createElement(Viewport, null, imageSourceSmall && React.createElement(ViewSmall, null, React.createElement(UitkImage, {
    blankDefault: false,
    lazyLoading: 'lazy',
    alt: alt,
    src: imageSourceSmall
  })), imageSourceMedium && React.createElement(ViewMedium, null, React.createElement(UitkImage, {
    blankDefault: false,
    lazyLoading: 'lazy',
    alt: alt,
    src: imageSourceMedium
  })), imageSourceLarge && React.createElement(ViewLarge, null, React.createElement(UitkImage, {
    blankDefault: false,
    lazyLoading: 'lazy',
    alt: alt,
    src: imageSourceLarge
  })), imageSourceExLarge && React.createElement(ViewExtraLarge, null, React.createElement(UitkImage, {
    blankDefault: false,
    lazyLoading: 'lazy',
    alt: alt,
    src: imageSourceExLarge
  })));
};