var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Header as HeaderBernie } from '@gc/global-components';
import { Footer as FooterBernie } from '@gc/global-components';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useStores } from 'utils/useStore';
import FallbackLanguageAlert from 'src/components/modules/fallback-language-alert/fallback-language-alert';
var MasterPage = function (props) {
  var children = props.children;
  var goGuidesStore = useStores().goGuidesStore;
  var track = useEgClickstream();
  useEffect(function () {
    var trackObject = {
      event: {
        event_version: '1.0.0',
        event_type: 'Page View',
        event_name: 'go_guides.viewed',
        event_category: 'go_guides'
      },
      experience: goGuidesStore.ClicksteamExperience
    };
    if (goGuidesStore.gaiaId) {
      var destination = {
        destination: {
          destination_geo_id: goGuidesStore.gaiaId
        }
      };
      trackObject = __assign(__assign({}, trackObject), destination);
    }
    track(trackObject);
  }, []);
  var FallBackAlert = function () {
    return goGuidesStore.isFallbackLanguage ? React.createElement(FallbackLanguageAlert, null) : null;
  };
  return React.createElement(React.Fragment, null, React.createElement(HeaderBernie, null), children, React.createElement(FallBackAlert, null), React.createElement(FooterBernie, null));
};
export { MasterPage };